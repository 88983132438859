import React from 'react';
import { PanelProps } from '@grafana/data';
import { EventsOverviewOptions } from './types';
import { getValuesFromQuery } from 'uef-common';

import { Alert, VerticalGroup, CustomScrollbar } from '@grafana/ui';

interface Props extends PanelProps<EventsOverviewOptions> {}

type Event = {
  message: string;
  description: string;
  machine: string;
  hazardous: number;
  critical: number;
  time: number;
  url: string;
};

export const EventsPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const events: Event[] = React.useMemo(() => {
    const messages: string[] = getValuesFromQuery(data, options.eventsQueryName, options.eventsMessageColumn);
    const descriptions: string[] = getValuesFromQuery(data, options.eventsQueryName, options.eventsDescriptionColumn);
    const machines: string[] = getValuesFromQuery(data, options.eventsQueryName, options.eventsMachineColumn);
    const hazardous: number[] = getValuesFromQuery(data, options.eventsQueryName, options.eventsHazardousColumn);
    const criticals: number[] = getValuesFromQuery(data, options.eventsQueryName, options.eventsCriticalColumn);
    const times: number[] = getValuesFromQuery(data, options.eventsQueryName, options.eventsTimeColumn);
    const urls: string[] = getValuesFromQuery(data, options.eventsQueryName, options.eventsUrlColumn);

    const _events: Event[] = [];
    for (let i = 0; i < times.length; i++) {
      _events.push({
        message: messages[i],
        description: descriptions[i],
        machine: machines[i],
        hazardous: hazardous[i],
        critical: criticals[i],
        time: times[i],
        url: urls[i],
      });
    }

    return _events;
  }, [data, options]);

  return (
    //@ts-ignore
    <CustomScrollbar>
      <VerticalGroup>
        {events.map((event, i) => {
          return (
            <Alert
              key={`evt-${i}`}
              title={`${new Date(event.time).toLocaleString()} - ${event.machine ?? ''}`}
              severity={event.critical ? 'error' : event.hazardous ? 'warning' : 'info'}
              onClick={() => {
                console.log('on alert click', i);
                if (event.url) {
                  window.open(event.url);
                }
              }}
            >
              <div>
                {event.message ?? ''} - {event.description ?? ''}
              </div>
            </Alert>
          );
        })}
      </VerticalGroup>
    </CustomScrollbar>
  );
};
