import { PanelPlugin } from '@grafana/data';
import { EventsOverviewOptions } from './types';
import { EventsPanel } from './EventsPanel';

export const plugin = new PanelPlugin<EventsOverviewOptions>(EventsPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'eventsQueryName',
      name: 'Event Beschreibung (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
    })
    .addTextInput({
      path: 'eventsMachineColumn',
      name: 'Name der Maschine (Spaltenname)',
      description: 'Name der Spalte',
    })
    .addTextInput({
      path: 'eventsDescriptionColumn',
      name: 'Beschreibung des Events (Spaltenname)',
      description: 'Name der Spalte',
    })
    .addTextInput({
      path: 'eventsTimeColumn',
      name: 'Zeitstempel (Spaltenname)',
      description: 'Name der Spalte',
    })
    .addTextInput({
      path: 'eventsMessageColumn',
      name: 'Nachricht des Events (Spaltenname)',
      description: 'Name der Spalte',
      defaultValue: '',
    })
    .addTextInput({
      path: 'eventsHazardousColumn',
      name: 'Gefährlich-Flag des Events (Spaltenname)',
      description: 'Name der Spalte',
      defaultValue: '',
    })
    .addTextInput({
      path: 'eventsCriticalColumn',
      name: 'Kritisch-Flag des Events (Spaltenname)',
      description: 'Name der Spalte',
      defaultValue: '',
    })
    .addTextInput({
      path: 'eventsUrlColumn',
      name: 'Zu öffnende Url bei Event Klick (Spaltenname)',
      description: 'Name der Spalte',
      defaultValue: '',
    });
});
